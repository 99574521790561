import React, { useEffect } from 'react'; 
import sendMailToAdmin from '../../services/sendMailToAdmin';
import ombra from "../../images/ombra-telefoni.png";

import ContactImg from "../../images/contactImg.png";
import bgInvert from '../../images/api-nere.png';

const ContactForm = () => { 

    const [nome, setNome] = React.useState(''); 
    const [mail, setMail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [oggetto, setOggetto] = React.useState('');
    const [testo, setTesto] = React.useState('');

    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const[success, setSuccess] = React.useState(false);
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleSend = async () => {
        if (mail.trim() !== '' && nome.trim() !== '' && phone.trim() !== '' && oggetto.trim !== '' && testo.trim() !== '') {

            await sendMailToAdmin('JOB', mail, nome, phone, oggetto, testo);
            
            setSuccess(true);
            setError(false);
            setMail('');
            setNome('');
            setPhone('');
            setOggetto('');
            setTesto('');

            setTimeout(() => {
                window.location.href = '/';
            }, 3000);
        } else {
            setErrorMessage('Attenzione, verifica di aver compilato tutti i campi obbligatori.');
            setError(true);

            setTimeout(() => {
                setSuccess(false);
                setError(false);
            }, 4000);
        }
    }

    return (
        <>
            <div className="bg-work">
                <div className="page-title-area overflow-hidden" style={{ alignContent: 'center', textAlign: 'center', paddingTop: isMobile ? 0 : '' }}>
                    <div className="container">
                        <div className="row justify-content-around">
                            <div className="section-title" style={{textAlign: 'start', marginBottom: 20, padding: '0 5%'}}>
                                <h2 className='text-uppercase Varela' style={{color:'rgb(49, 48, 206)', fontSize:'bold', fontSize:'32px', marginBottom:'1.5rem'}}>Lavora con noi</h2>
                                <p className='BrinnanLight m-0' style={{fontSize: '17px'}}>Se vuoi inviarci un CV puoi scrivere a <span style={{color:'rgb(49, 48, 206)'}}>info@appius.it</span>, ma non è importante. Ci piace conoscere le persone prima che vedere dei CV. Puoi compilare il form qui sotto e verrai ricontattato a breve.</p>
                            </div>
                            {
                                isMobile && (
                                    <div className="col-lg-5 col-md-12 position-relative">
                                        <img src={ContactImg} alt="image" style={{ paddingRight: isMobile ? 0 : 50, marginBottom:60, borderRadius:"15px", paddingTop: 0}} />
                                        <img src={ContactImg} className='bollaInvert' alt="image" />
                                    </div>
                                )
                            }
                            <div className="col-lg-5 col-md-12 position-relative">
                                <div className="row">

                                { error && <p style={{ color: '#E58080' }}>{errorMessage}</p> }
                                { success && <p style={{ color: '#72EB5F' }}>Messaggio inviato con successo, grazie!</p> }

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                name="name" 
                                                placeholder="Il tuo nome*" 
                                                className="form-control" 
                                                value={nome}
                                                onChange={event => setNome(event.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                name="email" 
                                                placeholder="Il tuo indirizzo e-mail*" 
                                                className="form-control"  
                                                value={mail}
                                                onChange={event => setMail(event.target.value)}
                                            /> 
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                name="number" 
                                                placeholder="Il tuo numero di telefono*" 
                                                className="form-control"  
                                                value={phone}
                                                onChange={event => setPhone(event.target.value)}
                                            /> 
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                name="subject" 
                                                placeholder="Oggetto*" 
                                                className="form-control"  
                                                value={oggetto}
                                                onChange={event => setOggetto(event.target.value)}
                                            /> 
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea 
                                                style={{ resize: 'none' }}
                                                name="text" 
                                                cols="30" 
                                                rows="5" 
                                                placeholder="Scrivi il tuo messaggio...*" 
                                                className="form-control"  
                                                value={testo}
                                                onChange={event => setTesto(event.target.value)}
                                            /> 
                                        </div>
                                    </div>

                                    <div className={`col-lg-12 col-sm-12 text-start${isMobile ? ' justify-content-center d-flex' : ' text-start'}`}>
                                        <button style={{background:'#8CCDB0'}} className="btn btn-primary" onClick={() => handleSend()}>INVIA</button>
                                    </div>
                                </div>
                            </div>
                            {
                                !isMobile && (
                                    <div className="col-lg-5 col-md-12 position-relative">
                                        <img src={ContactImg} alt="image" style={{ paddingRight: 50, marginBottom:60, borderRadius:"15px"}} />
                                        <img src={ContactImg} style={{bottom: isMobile ? -408 : ""}} className='bollaInvert' alt="image" />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <img src={ombra} className='ombraWork' alt="" />
                    <img src={bgInvert} className='bgInvert' alt="" />
                </div>
            </div>
        </>
    )
}

export default ContactForm;  