import React, { useEffect } from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/NavbarStyleFour";
import Footer from "../components/_App/Footer";
import WorkForm from "../components/Contact/WorkForm";
import { logPage } from "../analytics/analytics";

const Contact = () => {
    logPage("Lavora con noi");

    return (
        <Layout>
            <SEO title="Lavora con noi | APPIUS - Software House & Web Agency" />

            <Navbar />

            <WorkForm />

            <Footer />
        </Layout>
    );
};

export default Contact;
